import { memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidth};
`;

const Header = styled.h1`
  font-size: 3.2rem;
  font-weight: 800;
  letter-spacing: -0.03em;
  line-height: 1.1;
  margin-bottom: 1rem;
  padding: 1rem 0.5rem 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 3.6rem;
    padding-top: 1.5rem;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 6rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
`;

type TitleProps = {
  children: React.ReactNode;
};

const Title = ({ children }: TitleProps) => {
  return (
    <Wrapper>
      <Header>{children}</Header>
    </Wrapper>
  );
};

export default memo(Title);
